.project-page-container {
  background-color: #212121;
  color: #f5f5f5;
  width: calc(100vw - (100vw - 100%));

  height: 100vh;
  overflow: auto;
  text-align: justify;
}

.project-page-container ul {
  margin: 0;
}

.project-page-content {
  display: flex;
  padding-left: 2.5vw;
  padding-right: 2.5vw;
  padding-bottom: 10vh;
}

@media (max-width: 1600px) {
  .project-page-content {
    flex-direction: column;
  }
}

@media (min-width: 1600px) {
  .project-page-content {
    flex-direction: row;
    gap: 20px;
  }

  .project-page-description {
    width: 50%;
  }

  .project-page-demo {
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    width: 50%;
  }
}

.project-page-description {
  margin-top: 2.5%;
  margin-bottom: 2.5%;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  opacity: 0;
  animation: reveal 1.5s ease forwards;
  animation-delay: 0.1s;
}

.project-page-demo {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@keyframes reveal {
  to {
    opacity: 1;
  }
}

.project-page-video {
  width: 100%;
}
