.badge-container {
  display: flex;
}

.badge-text {
  padding: 0.5em;
  color: #f5f5f5;
  white-space: nowrap;
  background-color: #212121;
  border: 1px solid #f5f5f5;
  border-radius: 2px;
  transition: color 0.2s ease, background-color 0.2s ease;
}

.badge-text:hover {
  color: #212121;
  background-color: #f5f5f5;
}
