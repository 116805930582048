.education-preview-container {
  width: 50vw;
  height: auto;
  margin-left: 25vw;
  margin-right: 25vw;
  line-height: 20px;
}

@media (max-width: 1200px) {
  .education-preview-container {
    width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
  }

  .education-content-item-title {
    font-size: 18px;
    line-height: 24px;
  }
}

@media (min-width: 1200px) {
  .education-content-item-title {
    font-size: 26px;
    line-height: 30px;
  }
}

.education-content-item {
  padding-top: 2.5vh;
  padding-bottom: 2.5vh;
}

.certifications-item {
  display: flex;
  flex-direction: row;
}

.education-content-item-title {
  padding-bottom: 1%;
}

.certifications-title {
  width: 50%;
}

.certifications-distributor {
  width: 25%;
}

.certifications-received {
  width: 25%;
  text-align: end;
}
