.description {
  padding-top: 2.5vh;
  padding-bottom: 2.5vh;
  line-height: 20px;
  text-align: justify; /* Clean Formatting */
  text-align-last: center;
  width: 500px; /* Same Width as Headshot */
}

.explore {
  text-align: center; /* Center Horizontally */
}

.headshot {
  height: 500px; /* Height */
  width: 500px; /* Width */
}

.main-landing-content-container {
  width: calc(100vw - (100vw - 100%));
  /* Width */
  height: calc(95vh - 15px - 2vh); /* Height */
  display: flex; /* Flexbox */
  flex-direction: column; /* Align Items Vertically */
  justify-content: center; /* Center Vertically */
  align-items: center; /* Center Horizontally */
  opacity: 0; /* Invisible on Load */
  animation: reveal 1.5s ease forwards; /* Animation */
  animation-delay: 0.25s; /* Animation Delay */
}

@keyframes reveal {
  to {
    opacity: 1;
  }
}

.main-content-container .scroll-container {
  height: 15px;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

@media (max-width: 600px) {
  .description {
    width: 90vw;
  }

  .headshot {
    width: 90vw;
    height: auto;
  }
}
