.title-carousel-container {
  font-family: Roboto, sans serif;
  font-size: 20vh;
  width: calc(100vw - (100vw - 100%));

  height: fit-content;
  padding-bottom: 5vh;
  padding-top: 5vh;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
}

@media (max-width: 1200px) {
  .title-carousel-container {
    font-size: 15vh;
  }
}

.carousel {
  animation: slide 75s linear infinite;
}

.title-item {
  padding: 3vw;
}

@keyframes slide {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}
