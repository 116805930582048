.projects-preview-container {
  width: 50vw;
  height: auto;
  margin-left: 25vw;
  margin-right: 25vw;
}

@media (max-width: 1200px) {
  .projects-preview-container {
    width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
  }
}
