.app {
  font-family: "IBM Plex Mono", monospace;
  background-color: #f5f5f5;
}

.app::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

@media (max-width: 1200px) {
  .app {
    font-size: 12px;
  }
}

@media (min-width: 1200px) {
  .app {
    font-size: 14px;
  }
}

.scroll-text {
  position: absolute;
  bottom: -100%;
  width: 100%; /* Centering */
  animation: riseAndExitUp 3s cubic-bezier(0.25, 0.5, 0.25, 1) infinite; /* Animation */
}

.scroll-text-first {
  animation-delay: 1s; /* Delay */
}

.scroll-text-second {
  animation-delay: 2.5s; /* Delay */
}

.scroll-container {
  position: relative; /* Provides Scroll Container Bounds */
  overflow: hidden; /* Hides Double Text */
}

hr {
  border: none; /* Removes the default border */
  border-top: 1px solid #212121; /* Adds a top border with your chosen color and thickness */
}

@keyframes riseAndExitUp {
  0%,
  20% {
    bottom: -100%;
  }
  40%,
  60% {
    bottom: 0;
  }
  80%,
  100% {
    bottom: 100%;
  }
}
