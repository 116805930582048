.contact-me-container {
  width: 50vw;
  height: 30vh;
  margin-left: 25vw;
  margin-right: 25vw;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 15vh;
}

.contact-me-container a {
  color: #212121;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .contact-me-container {
    width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
  }
}

.contact-me-item {
  display: flex;
}

.contact-me-item-platform {
  width: 20%;
}

.contact-me-item-link {
  width: 80%;
  text-align: end;
}
