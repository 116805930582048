.experience-preview-container {
  width: 90vw;
  margin-left: 5vw;
  margin-right: 5vw;
}

.experience-preview-grid {
  display: flex;
  flex-direction: column;
  gap: 2.5vh;
}

@media (min-width: 1200px) {
  .experience-preview-grid {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .experience-preview-grid-item {
    width: calc(50% - 1.25vh);
  }
}
