.experience-page-container {
  background-color: #212121;
  color: #f5f5f5;
  width: calc(100vw - (100vw - 100%));
  height: 100vh;
  overflow: auto;
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 1%;
}
